import React from 'react';

import SelectField from '@airnz/ui/SelectField';
import { combineClassNames, createBem } from '@airnz/ui/bem';
import { Role } from '../../models/User';
import getSortFieldOptions from './getSortFieldOptions';

export type SortFieldValueType =
  | 'docketNumber'
  | 'dateAsc'
  | 'dateDesc'
  | 'createdBy'
  | 'aircraftRegistration'
  | 'airportCode'
  | 'volumeTransferred'
  | string; // These values are being saved in local storage which stores the above values as strings

export interface SortFieldProps {
  value: SortFieldValueType;
  onChange(value: string): void;
  role: Role;
  className?: string;
}

const bem = createBem('fuel-SortField');

const SortField = ({
  value,
  onChange,
  role,
  className,
}: SortFieldProps): JSX.Element => {
  const options = getSortFieldOptions(role);
  return (
    <SelectField
      id="sortField"
      label="Sort by"
      value={value}
      required
      onChange={onChange}
      options={options}
      className={combineClassNames(bem(), className)}
    />
  );
};

export default SortField;
