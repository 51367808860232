import { Role } from '../../models/User';

const getSortFields = (role: Role) => {
  const options = [
    {
      value: 'docketNumber',
      label: 'Docket number',
    },
    {
      value: 'dateAsc',
      label: 'Earliest',
    },
    {
      value: 'dateDesc',
      label: 'Latest',
    },
    {
      value: 'aircraftRegistration',
      label: 'Registration',
    },
  ];
  const createdBy = {
    value: 'createdBy',
    label: 'Created by',
  };
  const airportCode = {
    value: 'airportCode',
    label: 'Port',
  };
  switch (role) {
    case Role.support:
    case Role.admin:
      options.push(createdBy, airportCode);
      break;
    case Role.AOC:
      options.push(createdBy);
      break;
    default:
  }
  return options;
};

export default getSortFields;
