import {
  InteractionStatus,
  IPublicClientApplication,
} from '@azure/msal-browser';
import queryString from 'query-string';
import { AircraftConfigurationResponseData } from '../../models/AircraftData';
import BaseDocketData from '../../models/BaseDocketData';
import DocketData from '../../models/DocketData';
import { SearchParameters } from '../../models/DocketSearchParams';
import { UIConfiguration } from '../../models/UIConfigData';
import { ExtendedAccountInfo } from '../msal';
import { apiClientFactory } from './apiClient';

export type GetUiConfigResponse = UIConfiguration;
export type FetchDocketsRequest = Partial<SearchParameters>;
export type FetchDocketsResponse = {
  fuelDockets: DocketData[];
};
export type FetchDocketRequest = string;
export type FetchDocketResponse = DocketData;
export type CreateDocketRequest = {
  data: BaseDocketData;
};
export type CreateDocketResponse = DocketData;

export type EditDocketRequest = Partial<DocketData>;

export type EditDocketResponse = DocketData; // TODO check

export type GetAircraftDetailsConfigResponse = AircraftConfigurationResponseData;

export interface FuelDocketApiClient {
  getUiConfig(): Promise<GetUiConfigResponse>;
  fetchDockets(request: FetchDocketsRequest): Promise<FetchDocketsResponse>;
  fetchDocket(request: FetchDocketRequest): Promise<FetchDocketResponse>;
  createDocket(request: CreateDocketRequest): Promise<CreateDocketResponse>;
  editDocket(request: EditDocketRequest): Promise<EditDocketResponse>;
  getAircraftDetailsConfig(): Promise<GetAircraftDetailsConfigResponse>;
}

const apiBase = '/api/fuel-app/v1';

export const fuelDocketApiClientFactory = (
  instance: IPublicClientApplication,
  account: ExtendedAccountInfo | null,
  inProgress: InteractionStatus
): FuelDocketApiClient => {
  const apiClient = apiClientFactory({
    baseUrl: '',
    instance,
    account,
    inProgress,
  });
  return {
    getUiConfig(): Promise<GetUiConfigResponse> {
      return apiClient.request<undefined, GetUiConfigResponse>(
        `${apiBase}/ui-configurations`,
        'GET',
        undefined
      );
    },
    fetchDockets(
      searchParams: FetchDocketsRequest
    ): Promise<FetchDocketsResponse> {
      const isFueller = account?.idTokenClaims?.extension_fDocketFueller;
      if (isFueller) {
        delete searchParams.createdBy;
      }
      const url = `${apiBase}/fuel-dockets${
        searchParams && Object.keys(searchParams).length
          ? '?' + queryString.stringify(searchParams)
          : ''
      }`;
      return apiClient.request<undefined, FetchDocketsResponse>(
        url,
        'GET',
        undefined
      );
    },
    fetchDocket(docketId: FetchDocketRequest): Promise<FetchDocketResponse> {
      const url = `${apiBase}/fuel-dockets/${docketId}`;
      return apiClient.request<undefined, FetchDocketResponse>(
        url,
        'GET',
        undefined
      );
    },
    createDocket(request: CreateDocketRequest): Promise<CreateDocketResponse> {
      const url = `${apiBase}/fuel-dockets`;
      return apiClient.request(url, 'POST', request.data);
    },
    editDocket(request: EditDocketRequest): Promise<CreateDocketResponse> {
      const url = `${apiBase}/fuel-dockets/${request.fuelDocketId}`;
      delete request.fuelDocketId;
      return apiClient.request<EditDocketRequest, EditDocketResponse>(
        url,
        'PUT',
        request
      );
    },
    getAircraftDetailsConfig(): Promise<GetAircraftDetailsConfigResponse> {
      const url = `${apiBase}/aircraft-type-fuel-configurations`;
      return apiClient.request<undefined, GetAircraftDetailsConfigResponse>(
        url,
        'GET',
        undefined
      );
    },
  };
};
